let resumeData = {
    // "imagebaseurl":"",
    "name": "John Hicks",
    // "role": "",
    // "linkedinId":"Your LinkedIn Id",
    // "skypeid": "Your skypeid",
    "roleDescription": "I was born and raised in Michigan. After leaving Michigan in 2011, I had the opportunity to travel around the country and experience several things. It wasn't until I moved to Utah, that I realized that I have a passion for coding. I have a desire for learning new technologies and seeing what I can do with my code.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/j-hicks/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/alljsh/",
          "className":"fa fa-github"
        },
        {
          "name":"FaceBook",
          "url":"http://facebook.com/alljsh/",
          "className":"fa fa-facebook"
        },
        {
            "name":"Instagram",
            "url":"https://www.instagram.com/alljsh/",
            "className":"fa fa-instagram"
        }
      ],
    // "aboutme":"I am currently working for Wunderman Thompson (formerly known as J. Walter Thompson) and I have the privilege of working with several major clients and using cutting edge technology. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address":"Woodstock, GA",
    // "website":"https://johnhicks.me",
    "phonenumber":"(770) 500-9749",
    "email":"emailjohnhickshere@gmail.com",
    "education":[
      {
        "UniversityName":"DevMountain",
        "specialization":"Web Development",
        "MonthOfPassing":"November",
        "YearOfPassing":"2016",
        "Achievements":"Graduated and badged with the school",
        "url":"https://www.youracclaim.com/badges/c790dcf1-63c8-4449-82bf-2b6f419bc091"
      },
      {
        "UniversityName":"Davis Technical College",
        "specialization":"Information Technology",
        "MonthOfPassing":"April",
        "YearOfPassing":"2016",
        "Achievements":"CompTIA Strata IT Fundamentals",
        "url":"https://www.youracclaim.com/badges/515d19f5-d061-4220-9201-2cb587d35e5c/public_url"
      }
    ],
    "work":[
      {
        "CompanyName":"The Honey Baked Ham Company, LLC",
        "specialization":"Full Stack Developer",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2020 - Present",
        "Achievements":""
      },
      {
        "CompanyName":"Wunderman Thompson",
        "specialization":"Software Engineer",
        "MonthOfLeaving":"June",
        "YearOfLeaving":"2018 - 2020",
        "Achievements":"Work on various projects from some of our clients: Shell, Pennzoil, Quaker State, Jiffy Lube, USMC, Georgia Commute Options, SCANA Energy, Church's Chicken and Spirit Airlines."
      },
      {
        "CompanyName":"Pine Designs",
        "specialization":"CTO",
        "MonthOfLeaving":"March",
        "YearOfLeaving":"2018",
        "Achievements":"Do all IT related work for the company. Maintain a wholesale and retail e-commerce website while coding out a new site from scratch merging the two sites together."
      },
        {
            "CompanyName":"Rivetal",
            "specialization":"Web Developer",
            "MonthOfLeaving":"May",
            "YearOfLeaving":"2017",
            "Achievements":"Daily maintained and created sites and landing pages for various companies. Vasa Fitness, LoveNurtureGrow, CoreBridge, Corporate Alliance and Sorenson Media."
        }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      },
        {
        "skillname":"photoshop"
        },
        {
        "skillname":"JavaScript"
        }
    ],
    // "portfolio":[
    //   {
    //     "name":"project1",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/phone.jpg"
    //   },
    //   {
    //     "name":"project2",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/project.jpg"
    //   },
    //   {
    //     "name":"project3",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/project2.png"
    //   },
    //   {
    //     "name":"project4",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/phone.jpg"
    //   }
    // ],
    "testimonials":[
      {
        "description":"John is an exceptionally hard worker, something I saw again and again during our time at Devmountain. He's a talented developer with an eye for detail and I'm very glad to have gotten to opportunity to see him work and get to know him, and I'm certain he'd be an invaluable asset for any team.",
        "name":"Isaac Leavitt"
      },
      {
        "description":"John has shown his drive and ability to code multiple times during his time at DevMountain. He has the skills and knowledge to solve almost any problem he comes across as a developer, and succeeds at most things he puts his mind to. I hope to work with John in the future and would recommend him for any developer position.",
        "name":"Steven Isbell"
      },
        {
            "description":"I had the privilege of being one of John's programming mentors at DevMountain. One of the things that stood out to me for John was his ability to learn, to be resilient and to progress. I saw his struggles of learning new code, I saw the hours and hours spent perfecting his code, I saw a man of determination. Bright is John's future as a web developer and any employer would be lucky to have him on their team.",
            "name":"Craig Walker"
        }
    ]
  }

  export default resumeData
