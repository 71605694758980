import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './CarouselProvider.css'

export default class extends React.Component {
    render() {
        return (
            <CarouselProvider
                naturalSlideWidth={40}
                naturalSlideHeight={16}
                totalSlides={9}
                isPlaying={true}
                interval={2000}
                playDirection={"forward"}
                infinite={true}
                orientation={"horizontal"}
                // hasMasterSpinner={true}
            >
                <Slider>
                    <Slide index={0}>
                        <div style={{display:'flex', justifyContent: 'space-around'}}>
                            <a href="https://www.shell.com/" target="_blank" rel="noopener noreferrer"><img id='shell' style={{width:250, textAlign: 'center'}} src={require("../assets/images/shell.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={1}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.pennzoil.com/" target="_blank" rel="noopener noreferrer"><img id='pennzoil' style={{width:375, textAlign: 'center'}} src={require("../assets/images/pennzoil.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={2}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.quakerstate.com/" target="_blank" rel="noopener noreferrer"><img id='quakerstate' style={{width:300, textAlign: 'center'}} src={require("../assets/images/quakerstate.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={3}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.jiffylube.com/" target="_blank" rel="noopener noreferrer"><img id='jiffylube' style={{width:550, textAlign: 'center', paddingTop: 40}} src={require("../assets/images/jiffylube2.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={4}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.churchs.com/" target="_blank" rel="noopener noreferrer"><img id='churchschicken' style={{width:250, textAlign: 'center'}} src={require("../assets/images/churchschicken.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={5}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.spirit.com/" target="_blank" rel="noopener noreferrer"><img id='spiritairlines' style={{width:450, textAlign: 'center'}} src={require("../assets/images/spiritairlines.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={6}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.scanaenergy.com/" target="_blank" rel="noopener noreferrer"><img id='scanaenergy' style={{width:550, textAlign: 'center', paddingTop: 60}} src={require("../assets/images/scanaenergy.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={7}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://www.marines.com/" target="_blank" rel="noopener noreferrer"><img id='marines' style={{textAlign: 'center', maxHeight:250}} src={require("../assets/images/marines2.png")} alt=""/></a>
                        </div>
                    </Slide>
                    <Slide index={8}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <a href="https://gacommuteoptions.com/" target="_blank" rel="noopener noreferrer"><img id='gco' style={{width:550, textAlign: 'center'}} src={require("../assets/images/GCO.png")} alt=""/></a>
                        </div>
                    </Slide>
                </Slider>
                <div style={{display: 'flex', justifyContent: 'space-evenly', textAlign:'center'}}>

                <ButtonBack>&#x21E6;</ButtonBack>
                <ButtonNext>&#x21E8;</ButtonNext>

                </div>
            </CarouselProvider>
        );
    }
}
