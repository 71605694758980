import React, { Component } from 'react';
import CarouselProvider from "./CarouselProvider";
export default class Porfolio extends Component {
  render() {
    // let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div style={{display: 'flex', justifyContent: 'center', padding: "0 30px 40px", fontSize: 30}}>Some companies I've worked with:</div>
      <div className="row">
        <div className="twelve columns collapsed">
          <CarouselProvider/>
          {/*<div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">*/}
          {/*{*/}
          {/*  resumeData.portfolio && resumeData.portfolio.map((item, index)=>{*/}
          {/*    return(*/}
          {/*      <div key={index} className="columns portfolio-item">*/}
          {/*        <div className="item-wrap">*/}
          {/*          <a href="#modal-01">*/}
          {/*            <img src={`${item.imgurl}`} className="item-img" alt=""/>*/}
          {/*            <div className="overlay">*/}
          {/*              <div className="portfolio-item-meta">*/}
          {/*                <h5>{item.name}</h5>*/}
          {/*                <p>{item.description}</p>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  })*/}
          {/*}*/}
          {/*</div>*/}
        </div>
      </div>
  </section>
        );
  }
}
